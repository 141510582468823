import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { STRINGS } from '../../constants/strings';
import { URL } from '../../constants/urls';
import '../../css/components/_bonmarcheCompetitions-form.scss';

import { validateEmail } from '../../utils/articleUtil';
const BonmarcheForm = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [dob, setDob] = useState(null);
  const [inspiration, setInspiration] = useState('');
  const [tnc, setTnC] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const refs = {
    firstName: useRef(null),
    lastName: useRef(null),
    email: useRef(null),
    dob: useRef(null),
    inspiration: useRef(null),
    tnc: useRef(null),
    marketing: useRef(null),
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setFirstName(params.firstName || '');
    setLastName(params.lastName || '');
    setEmailAddress(params.email || '');
  }, []);

  const onActionClick = async (action) => {
    switch (action) {
      case 'yes':
        if (validateForm()) {
          setError({});
          setLoading(true);
          const name = `${firstName} ${lastName}`;
          const birthday = new Intl.DateTimeFormat('en-GB').format(dob).replaceAll('/', '-')
          const tags = 'Bonmarche';

          fetch(`${URL.SERVER}/subscribe?name=${name}&email=${emailAddress}&fields=645715:${birthday},934515:${inspiration}&marketing=${marketing}&website=${STRINGS.SUBSCRIPTION_TAG}&tag=${tags}&list=BONMARCHE`)
          .then(resp => resp.json())
            .then(res => {
              setLoading(false);
              setFirstName('');
              setLastName('');
              setEmailAddress('');
              setDob(null);
              setInspiration('');
              setTnC(false);
              setMarketing(false);
              navigate('/thankyou?returnUrl=/bonmarche');
            });

        break;
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    let firstErrorField = null;
    if (!firstName.trim()) {
      errors.firstName = 'Please enter first name';
      if (!firstErrorField) firstErrorField = refs.firstName.current;
    }
    if (!lastName.trim()) {
      errors.lastName = 'Please enter last name';
      if (!firstErrorField) firstErrorField = refs.lastName.current;
    }
    if (!emailAddress || !validateEmail(emailAddress)) {
      errors.email = 'Please enter a valid email address';
      if (!firstErrorField) firstErrorField = refs.email.current;
    }
    if (!dob) {
      errors.dob = 'Please enter your birthday';
      if (!firstErrorField) firstErrorField = refs.dob.current;
    }
    if (!inspiration.trim()) {
      errors.inspiration = 'Please provide where you seek inspiration when shopping for fashion';
      if (!firstErrorField) firstErrorField = refs.inspiration.current;
    }
    if (!tnc) {
      errors.tnc = 'Please accept Terms and Privacy Policy';
      if (!firstErrorField) firstErrorField = refs.tnc.current;
    }
    if (!marketing) {
      errors.marketing = 'To participate in the prize draw we need your consent to contact you';
      if (!firstErrorField) firstErrorField = refs.marketing.current;
    }

    setError(errors);

    if (firstErrorField && typeof firstErrorField.scrollIntoView === 'function') {
      firstErrorField.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      firstErrorField.focus();
    }

    return Object.keys(errors).length === 0;
  };

  return (
    <div className="bonmarche-form">
      <div className="container">
        <h1>Win two new outfits with Bonmarché!</h1>
        {error.submit && <div className="text-red">{error.submit}</div>}
        <div className="input-container">
          <label htmlFor="firstName">First name</label>
          <input
            id="firstName"
            type="text"
            placeholder="Enter your first name"
            value={firstName}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, firstName: '' }));
              setFirstName(event.target.value);
            }}
            ref={refs.firstName}
          />
          {error.firstName && <div className="text-red">{error.firstName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="lastName">Last name</label>
          <input
            id="lastName"
            type="text"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, lastName: '' }));
              setLastName(event.target.value);
            }}
            ref={refs.lastName}
          />
          {error.lastName && <div className="text-red">{error.lastName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="email">Email address</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email address"
            value={emailAddress}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, email: '' }));
              setEmailAddress(event.target.value);
            }}
            ref={refs.email}
          />
          {error.email && <div className="text-red">{error.email}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="dob">Birthday</label>
          <div>
            <DatePicker
              id="dob"
              selected={dob}
              onChange={(date) => {
                setError(prevError => ({ ...prevError, dob: '' }));
                setDob(date);
              }}
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              dateFormat="dd/MM"
              placeholderText="Select your birthday"
              className="custom-datepicker"
              ref={refs.dob}
            />
          </div>
          {error.dob && <div className="text-red">{error.dob}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="inspiration">Where do you seek inspiration when shopping for fashion?</label>
          <input
            id="inspiration"
            type='text'
            placeholder="Enter your source of inspiration"
            value={inspiration}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, inspiration: '' }));
              setInspiration(event.target.value);
            }}
            ref={refs.inspiration}
          />
          {error.inspiration && <div className="text-red">{error.inspiration}</div>}
        </div>
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="tnc"
            checked={tnc}
            onChange={() => setTnC(!tnc)}
            ref={refs.tnc}
          />
          <label htmlFor="tnc">I have read and accept the <a className="cursor-pointer underline" href="/bonmarche-terms" target="_blank">Privacy Policy and Terms & Conditions</a>.</label>
        </div>
        {error.tnc && <div className="text-red">{error.tnc}</div>}
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="marketing"
            checked={marketing}
            onChange={() => setMarketing(!marketing)}
            ref={refs.marketing}
          />
          <label htmlFor="marketing">I’m happy for Bonmarché to send me selected marketing information which may be of interest to me.</label>
        </div>
        {error.marketing && <div className="text-red">{error.marketing}</div>}
        <div className="action-container mt-3">
          <button
            onClick={() => onActionClick('yes')}
            type="button"
            className="bg-red py-4 px-12 text-white rounded-[19px]"
          >
            SUBMIT MY ENTRY {loading && <img alt="loading" src="/images/loading.svg" style={{ marginLeft: '5px' }} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BonmarcheForm;
