import React from 'react';
import Helmet from 'react-helmet';
import { metadata } from '../../config';
import BonmarcheForm from '../components/bonmarcheCompetition-form';
import Layout from '../components/layout';

const Bonmarche = () => {
  const url = `${metadata.url}/bonmarche`;

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={url} />
      </Helmet>
      <main className="wrapper main-data-container pt-5 font-outfit">
        <div className="text-5xl lg:text-6xl mb-6 lg:mb-8" style={{ color: '#2FB7D9', lineHeight: '84.5%', fontWeight: 600 }}>
          <img className="mb-4" src="/images/logos/age-group.svg" width={248} height={53} alt="age group logo" />
          <div>Win two new outfits with Bonmarché!</div>
        </div>
        <div className="grid lg:grid-cols-2">
          <div className="col-span-1 text-2xl" style={{ lineHeight: '1' }}>
            <img className="rounded-2xl mb-7 object-cover h-[319px] w-full" height={319} alt="cover image" src=" https://www.datocms-assets.com/38567/1723094426-bonmarche-competition.jpg" />
            <p>To celebrate the launch of their new collection, Bonmarché is offering Age Times readers the chance to win two new outfits, helping you look and feel great this season!</p>
            <p>With figure flattering styles, all in sizes 10-28, Bonmarché offers styles for every occasion, with transitional pieces to prepare you for the unpredictable British weather.</p>
            <p>Shop these new styles with the chance to pick 2 new outfits to the value of £100. Plus, 5 runner-ups will win a beautiful lightweight scarf to brighten up those cooler days.</p>
            <p>Plus, exclusively for Age Times readers, Bonmarché is offering 20% off all full priced styles online. Enter the code AGECOMP24 at the checkout to receive 20% off qualifying items.</p>
          </div>
          <div className="col-span-1">
            <BonmarcheForm />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Bonmarche;
